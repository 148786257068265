import React from "react"
import Icon from "./icon"

import projectsStyles from "../styles/projects.module.css"

const Project = ({ title, link, github, description }) => (
  <div className={projectsStyles.project}>
    <div className={projectsStyles.heading}>
      {link ? <a href={link}>{title}</a> : <h4>{title}</h4>}
      {github ? (
        <a href={github}>
          <Icon name="github" color="#bbb" size={24} />
        </a>
      ) : null}
    </div>
    {description.split("\n").map(line => (
      <p>{line}</p>
    ))}
  </div>
)

const ProjectGrid = ({ children }) => (
  <div className={projectsStyles.projects}>{children}</div>
)

const Projects = ({ projects }) => (
  <div className="container">
    <ProjectGrid>
      {projects.map(project => (
        <Project
          title={project.title}
          link={project.link}
          github={project.github}
          description={project.description}
        />
      ))}
    </ProjectGrid>
  </div>
)

export default Projects
