import React from "react"
import { Helmet } from "react-helmet"
import Icon from "../components/icon"
import Projects from "../components/projects"

import indexStyles from "../styles/index.module.css"

import myFace from "../assets/my face in a circle.svg"

const projects = [
  {
    title: "unsweetener",
    link: "http://unsweetener.netlify.com",
    github: "https://github.com/zdim/unsweetener",
    description: `simple web app for searching foods and drinks to see which sweeteners are added
      built with react & node, using the usda's rest apis
      hosted with netlify`,
  },
  {
    title: "what should I play",
    link:
      "https://discordapp.com/oauth2/authorize?client_id=548564890902593544&scope=bot&permissions=387072",
    github: "https://github.com/zdim/steam-game-getter",
    description: `discord bot that recommends games to play for either one person or a group of people
      built with node, using discord.js and the steam & igdb rest apis
      hosted with heroku`,
  },
  {
    title: "habits",
    description: `self-help & life planning tool that schedules events and tracks a daily routine
      built with react native and firebase`,
    github: "https://github.com/zdim/habits-app",
  },
  {
    title: "aloha to quickbooks converter",
    description: `tool that converts reports from aloha into quickbooks journal entries
      built with c# .net, using winforms`,
    github: "https://github.com/zdim/aloha-to-qb",
  },
  {
    title: "this website",
    description: `a portfolio outlining how great of a developer I am and displaying all of my projects
      built with react using gatsby
      hosted with netlify`,
  },
  {
    title: "dimarco & associates website and blog",
    description: `website and blog for a tampa bay-based accounting firm
      designed with figma. built with html & css. the blog using wordpress
      hosted on aws and bluehost`,
  },
  {
    title: "liquid courage",
    link:
      "http://gameproject.fullsail.com/gpgames/index.php/2015/03/liquid-courage/",
    description: `action video game inspired by gauntlet and diablo, created as a final project on a team of 7 students
      prototyped using c# in unity, built from scratch using c++`,
  },
  {
    title: "evoark",
    github: "https://github.com/zdim/evoark",
    description: `top-down space-themed shooter video game created on a team of 3 students
    built using c++`,
  },
]

const SkillIcon = ({ name, tooltip }) => (
  <div className={indexStyles.skill}>
    <Icon name={name} color="#bbb" size={36} />
    <h5>{tooltip}</h5>
  </div>
)

export default () => (
  <div>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Zach DiMarco | Web Developer</title>
      <meta name="description" content={"Full Stack Web Developer in the Denver/Boulder area. Freelance, contract work, or just to chat - feel free to get in touch!"}/>
    </Helmet>
    <div className={indexStyles.landing}>
      <div className={indexStyles.circlePicture}>
        <img src={myFace} alt="my face" />
      </div>
      <h1>zach dimarco</h1>
      <h3>dev | design</h3>
    </div>
    <div className={indexStyles.quote}>
      <blockquote>"simplicity is the ultimate sophistication"</blockquote>
    </div>
    <div className={indexStyles.section}>
      <h5>
        find me on{" "}
        <a href="https://www.linkedin.com/in/zachary-dimarco-023319b7/">
          linkedin
        </a>{" "}
        or send me an <a href="mailto:zdim227@gmail.com">email</a>
      </h5>
    </div>
    <div className={indexStyles.section}>
      <h3>what I do</h3>
      <div className={indexStyles.skills}>
        <SkillIcon name="csharp" tooltip="c# (.net)" />
        <SkillIcon name="js" tooltip="javascript" />
        <SkillIcon name="react" tooltip="react" />
        <SkillIcon name="node" tooltip="node" />
        <SkillIcon name="html" tooltip="html" />
        <SkillIcon name="css" tooltip="css" />
        <SkillIcon name="wordpress" tooltip="wordpress" />
      </div>
      <Projects projects={projects} />
    </div>
  </div>
)
